@import 'variables';
@import 'reset';
@import 'mixins';
@import 'fonts';
@import 'png_sprite';

@import 'slick-carousel/slick/slick.css';

@import 'base';

/*common blocks*/
@import 'blocks/nav';
@import 'blocks/red-btn';
@import 'blocks/popup-shadow';
@import 'blocks/advantages';
@import 'blocks/services';
@import 'blocks/feedback-form';
@import 'blocks/popup';
@import 'blocks/send-block';
@import 'blocks/phone-block';

/*sections*/
@import 'sections/header';
@import 'sections/footer';
@import 'sections/top';
@import 'sections/prices';
@import 'sections/form';
@import 'sections/work';
@import 'sections/steps';
@import 'sections/guaranty';
@import 'sections/reviews';
@import 'sections/contacts';
@import 'sections/instagram';
