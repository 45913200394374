.site-footer {
  background-color: #000;
  color: white;
  font-size: 14px;
  line-height: 16px;
  transition: all 0.5s;
  a {
    color: white;
    display: inline-block;
    margin-left: 6px;
  }
  &__wrapper {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1320px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    @media ($tablet) {
      flex-wrap: wrap;
      height: 274px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 11px;
      border-bottom: 5px solid $light;
    }
    @media ($mobile) {
      align-content: flex-start;
      height: 288px;
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 43px;
      border-bottom: none;
    }
  }
  &__text {
    padding-top: 11px;
    &--right {
      text-align: right;
      padding-top: 0;
    }
    @media ($tablet) {
      width: 50%;
      order: 4;
      padding-top: 9px;
    }
    @media ($mobile){
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      &--right {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
  &__logo {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    text-align: center;
    @media ($tablet) {
      width: 100%;
      order: 1;
    }
    @media ($mobile) {
      margin-bottom: 30px;
    }
    svg {
      fill: white;
    }
  }

  &.show-phone {
    padding-bottom: 87px;
  }
}

.social {
  margin-left: auto;
  margin-right: 60px;
  @media ($tablet) {
    width: 100%;
    order: 3;
    text-align: center;
    margin: 0;
  }
  a.social__link {
    margin-left: 40px;
    img {
      height: 24px;
    }
    @media ($tablet) {
      margin: 0;
      border-right: 2px solid rgba($color: #FFFFFF, $alpha: 0.2);
      margin-right: 40px;
      padding-right: 40px;
      &:last-child {
        margin-right: 0;
        border-right: none;
        padding-right: 0;
      }
    }
  }
}
