.reviews {
  position: relative;
  padding-top: 140px;
  padding-bottom: 100px;
  background: url(../images/texture2.jpg) no-repeat;
  background-size: cover;
  background-position: center 0;
  overflow: hidden;
  @media ($tablet) {
    padding-top: 111px;
    padding-bottom: 91px;
  }
  @media ($mobile) {
    background-size: 100%;
    background-repeat: repeat;
    padding-top: 61px;
    padding-bottom: 30px;
  }
  &:after {
    position: absolute;
    content: "";
    width: 428px;
    height: 475px;
    background: url("../images/bolgar.png") no-repeat;
    background-size: cover;
    //top: calc(50% - 235px);
    top: calc(50% + 45px);
    right: 0;
    @media ($desktop-xs) {
      right: -100px;
    }
    @media ($tablet) {
      right: -189px;
    }
    @media ($mobile) {
      width: 201px;
      height: 219px;
      right: -100px;
      top: calc(50% + 200px);
    }
  }
  &:before {
    position: absolute;
    content: "";
    width: 293px;
    height: 599px;
    background: url("../images/spray.png") no-repeat;
    background-size: cover;
    bottom: -105px;
    left: 0;
    @media ($desktop-xs) {
      left: -100px;
    }
    @media ($tablet) {
      left: -107px;
      bottom: -116px;
    }
    @media ($mobile) {
      left: -60px;
      bottom: -110px;
      width: 170px;
      height: 320px;
    }
  }
  &__wrapper {
    margin: 0 auto;
    max-width: 1320px;
    padding-left: 20px;
    padding-right: 20px;
    @media ($tablet) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media ($mobile) {
      padding-left: 17px;
      padding-right: 17px;
    }
  }


  &__about {
    display: flex;
    @media ($mobile) {
      display: block;
    }
  }
  &__reviews {
    padding-top: 100px;
    @media ($tablet) {
      padding-bottom: 151px;
    }
    @media ($mobile) {
      padding-top: 50px;
      padding-bottom: 0;
    }
  }
  &__about-image {
    position: relative;
    flex-shrink: 0;
    padding-right: 28px;
    border-right: 5px solid $light;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -11px;
      height: 100%;
      width: 1px;
      background-color: $light;
    }
    @media ($tablet) {
      width: 50.5%;
      padding-right: 22px;
    }
    @media ($mobile) {
      width: 100%;
      padding-right: 0;
      border-right: 0;
      margin-bottom: 24px;
      text-align: center;
      img {
        display: inline-block;
      }
      &:after {
        display: none;
      }
    }

  }
  &__about-content {
    font-size: 18px;
    line-height: 24px;
    padding-left: 36px;
    padding-right: 110px;
    @media ($tablet) {
      padding-right: 0;
      padding-left: 21px;
      font-size: 16px;
      line-height: 24px;
    }
    @media ($mobile) {
      padding-left: 0;
    }
  }
  &__about-strong {
    margin-top: 0;
    margin-bottom: 18px;
    font-family: "DIN Condensed";
    text-transform: uppercase;
    font-size: 28px;
    line-height: 32px;
    @media ($tablet) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 27px;
    }
    @media ($mobile) {
      margin-bottom: 7px;
    }
  }
  &__about-text {
    &:last-child {
      margin-bottom: 0;
    }
    @media ($tablet) {
      padding-right: 10px;
      margin-bottom: 21px;
    }
    @media ($mobile) {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
  &__title {
    font-family: "AlgeriaTwo";
    font-size: 64px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 50px;
    line-height: 1;
    text-align: center;
    @media ($tablet) {
      margin-bottom: 48px;
      font-size: 42px;
      margin-bottom: 50px;
    }
    @media ($mobile) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 39px;
      text-align: center;
    }
  }

  &__slider {
    display: flex;
    max-width: 975px;
    margin: 0 auto;
    @media ($mobile) {
      max-width: 500px;
    }
  }
  &__slider-item {
    text-align: center;
    padding-left: 60px;
    padding-right: 60px;
    &:active,
    &:focus {
      outline: none;
    }
    @media ($tablet) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ($mobile){
      padding-right: 0;
      padding-left: 0;
    }
  }
  &__slider-image {
    display: inline-block;
    height: 180px;
    width: 180px;
    img {
      display: inline-block;
      border-radius: 50%;
      border: 5px solid white;
      width: auto;
      height: auto;
      @supports (object-fit: cover ) {
        width: 100%;
        height: 100%;
      }
      object-fit: cover;
      object-position: center center;
      box-sizing: content-box;
    }
  }
  &__slide-content {
    margin-top: 13px;
    padding-top: 29px;
    background-image: url("../images/icon-quote.png");
    background-repeat: no-repeat;
    background-size: 42%;
    background-position: center 0;
    @media ($tablet) {
      margin-top: 3px;
    }

    @media ($mobile) {
      background-image: url(../images/icon-quote-min.png);
      background-size: 40%;
      background-position: center center;
      background-repeat: no-repeat;
    }
    @media (max-width: 400px) {
      background-size: 80%;
    }

  }
  &__text {
    margin-top: 0;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 40px;
    @media ($mobile) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 34px;
    }
  }
  &__name {
    font-family: "DIN Condensed";
    text-transform: uppercase;
    font-size: 28px;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 9px;
    @media ($mobile) {
      font-size: 20px;
      margin-bottom: 6px;
    }
  }
  &__car {
    margin-top: 0;
    font-size: 18px;
    @media ($mobile) {
      font-size: 14px;
      margin-bottom: 0;
    }

  }
  .slick-prev,
  .slick-next {
    font-size: 0;
    width: 30px;
    height: 56px;
    border: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    padding: 0;
    flex-shrink: 0;
    margin-top: 256px;
    &:active,
    &:focus {
      outline: none;
    }
    @media ($tablet) {
      margin-top: 264px;
    }
    @media ($mobile) {
      position: absolute;
      margin-top: 60px;
      z-index: 4;
    }
  }

  .slick-next {
    background-image: url("../images/arrow-next.svg");
    @media ($mobile) {
      right: 0;
    }
  }
  .slick-prev {
    background-image: url("../images/arrow-prev.svg");
  }
}
