.instagram {
  margin-top: 153px;
  margin-bottom: 56px;
  @media ($tablet) {
    margin-top: 80px;
    margin-bottom: 30px;
  }
  @media ($mobile) {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .reviews__title {
    margin-bottom: 66px;
    @media ($mobile) {
      margin-bottom: 30px;
    }
  }
  &__list {
    display: flex;
    @media ($tablet) {
      flex-wrap: wrap;
    }
  }
  &__item {
    width: 25%;
    height: 300px;
    border-right: 2px solid white;
    &:nth-child(4n) {
      border-right: 0;
      border-bottom: 0;
    }
    @media ($tablet) {
      width: 50%;
      border-bottom: 2px solid white;
      height: 302px;
      &:nth-child(2n) {
        border-right: 0;
      }
      &:nth-child(3) {
        border-bottom: 0;
      }
    }
    @media ($mobile) {
      width: 250px;
      border-right: 0;
      height: 200px;
      overflow: hidden;
      &:nth-child(2n) {
        border-right: 0;
        border-bottom: 2px solid white;
      }
      &:nth-child(3) {
        border-bottom: 2px solid white;
      }
      &:nth-child(4n) {
        border-bottom: 2px solid white;
      }
    }
  }
  &__link {
    display: block;
    color: white;
    font-size: 0;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &--logo {
      background-color: #ddcfb3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 35px;
      line-height: 1;
      &:hover{
        background-color: darken(#ddcfb3,20%);
        @media ($tablet) {
          background-color: #ddcfb3;
        }
      }
      img {
        margin-bottom: 33px;
        height: initial;
        width: initial;
      }

    }
  }

  .slick-slide {
    margin: 0 5px;
  }
  .slick-list {
    margin-right: -20px;
  }
}
