.main-nav {
  padding-left: 30px;
  @media ($desktop-md) {
    padding-left: 19px;
    padding-top: 4px;
  }
  @media ($mobile){
    padding-top: 13px;
  }
  &__cont {
    transition: all 0.3s;
    @media ($desktop-md) {
      position: fixed;
      width: 100%;
      transform: translateX(150%);
      right: 0;
    }
    @media ($desktop-md) {
      overflow-y: scroll;
      height: 100%;
      position: fixed;
      width: 507px;
      right: 0;
      transform: translateX(150%);
      top: 0;
      z-index: 11;
      background-color: #000;
      padding-left: 34px;
      padding-right: 28px;
    }
    @media ($mobile) {
      width: 100%;
      padding-left: 17px;
      padding-right: 0;
    }

    &:focus {
      outline: none;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    @media ($desktop-md) {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
      margin-right: 0;
      padding-top: 43px;
      padding-bottom: 43px;
    }
  }
  &__mob-top {
    display: none;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light;
    padding-top: 30px;
    padding-bottom: 40px;
    svg {
      fill:white;
    }
    @media ($desktop-md) {
      display: flex;
    }
    @media ($mobile) {
      padding-right: 17px;
    }
  }
  &__mob-bottom {
    display: none;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $light;
    padding-top: 43px;
    padding-bottom: 43px;
    @media ($desktop-md) {
      display: flex;
    }
    @media ($mobile) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &__socials {
    display: flex;
    align-items: flex-end;
    @media ($mobile) {
      padding-top: 20px;
    }
  }
  &__social-link {
    display: flex;
    align-items: center;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    &:nth-child(2){
      margin-right: 24px;
    }
    svg {
      fill: white;
    }
  }
  &__mob-close {
    background: transparent;
    border: none;
    padding: 0;
    svg {
      fill: white;
    }
  }
  &__phone {
    font-size: 24px;
    line-height: 38px;
    color: white;
    text-decoration: none;
  }
  &__item {
    flex-shrink: 0;
    margin-left: 15px;
    margin-right: 15px;
    @media ($desktop-md) {
      padding-top: 23px;
      padding-bottom: 23px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
  &__link {
    font-family: "DIN Condensed";
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    text-decoration: none;
    color: $black;
    @media ($desktop-md) {
      color: white;
      &.current {
        color: $red;
      }
    }
  }
  &__burger {
    display: none;
    background: transparent;
    border: none;
    padding: 0;
    padding-top: 8px;
    transition: filter 0.3s;
    @media ($desktop-md) {
      display: block;
    }
    @media ($desktop-md) {
      padding-top: 4px;
    }
  }


}
