.guaranty {
  margin: 0 auto;
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 125px;
  @media ($tablet) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 103px;
    padding-bottom: 105px;
  }
  @media ($mobile) {
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 60px;
    padding-bottom: 0;
  }
  &__title {
    font-family: "AlgeriaTwo";
    font-size: 64px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 77px;
    line-height: 1;
    @media ($tablet) {
      margin-bottom: 48px;
      font-size: 42px;
      margin-bottom: 50px;
    }
    @media ($mobile) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 26px;
      text-align: center;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    @media ($tablet) {
      display: block;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 49%;
    border-bottom: 3px solid $light;
    border-top: 3px solid $light;
    padding-top: 19px;
    padding-bottom: 7px;
    padding-left: 21px;
    @media ($tablet) {
      width: 100%;
      margin-bottom: 60px;
    }
    @media ($mobile){
      padding-left: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 0;
    }
    svg {
      flex-shrink: 0;
      fill: #272727;
      @media ($mobile){
        width: 80px;
        height: auto;
      }
    }
    &:after {
      left:0;
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $light;
      bottom: -8px;
    }
    @media ($mobile) {
      &:first-child{
        border-bottom: 0;
        &:after {
          display: none;
        }
      }
    }
  }

  &__content {
    padding-left: 47px;
    @media ($mobile){
      padding-left: 14px;
    }
  }

  &__content-title {
    font-size: 24px;
    font-family: "DIN Condensed";
    text-transform: uppercase;
    font-weight: normal;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 32px;
    @media ($mobile) {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 22px;
    padding-right: 32px;
    @media ($mobile) {
      font-size: 14px;
      line-height: 17px;
      padding-right: 0;
      margin-bottom: 0;
    }
  }

}
