.red-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: "DIN Condensed";
  text-transform: uppercase;
  border: none;
  background-color: $red;
  min-width: 259px;
  min-height: 70px;
  font-size: 24px;
  line-height: 38px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 45px;
  padding-left: 45px;
  text-align: center;
  color: $white;
  text-decoration: none;


}
