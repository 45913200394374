.form {
  padding-top: 214px;
  padding-bottom: 238px;
  background: url(../images/form-bg.jpg) no-repeat;
  background-size: cover;
  @media ($tablet) {
    padding-top: 114px;
    padding-bottom: 100px;
    padding-left: 30px;
    padding-right: 30px;
  }
  @media ($mobile) {
    padding-top: 30px;
    padding-bottom: 53px;
    padding-left: 17px;
    padding-right: 17px;
  }
}
