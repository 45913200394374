*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    font-size: 62.5%;
    &.no-overflow {
      overflow: hidden;
      position: relative;
      height: 100%;
    }
}

body {
    line-height: 1.5;

    @media ($desktop-s) {
      overflow-x:hidden;
    }
    @media ($mobile){
      padding-top: 91px;
    }
}

address {
    font-style: normal;
}

button {
    cursor: pointer;
}

img {
    height: auto;
    max-width: 100%;
}

label {
    cursor: pointer;
    user-select: none;
}

textarea {
    resize: vertical;
}

ul {
    @include list-reset;
}

@include placeholder {
    text-overflow: ellipsis;
}

input[placeholder] {
    text-overflow:ellipsis;
}

input::-moz-placeholder {
    text-overflow:ellipsis;
}

input:-moz-placeholder {
    text-overflow:ellipsis;
}

input:-ms-input-placeholder {
    text-overflow:ellipsis;
}

:focus::-webkit-input-placeholder {
    color: transparent;
}

:focus::-moz-placeholder {
    color: transparent;
}

:focus:-moz-placeholder {
    color: transparent;
}

:focus:-ms-input-placeholder {
    color: transparent;
}

.responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: $container-width;
}

.site-wrapper {
    display: flex;
    flex-direction: column;
    font-family:"HelveticeNeue";
    font-size: 1.6rem;
    min-height: 100vh;
}

.site-content {
    flex: 1 0 auto;
    transition: filter 0.3s;
}
.hideMob {
  @media ($mobile) {
    display: none;
  }
}
