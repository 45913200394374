.phone-block {
    display: none;
    background-color: #fff;
    border-top: 5px solid #ddcfb3;
    height: 87px;
    @media ($mobile){
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 6;
        width: 100%;
        transition: all 0.5s;
        box-shadow: 0px 10px 89px 7px rgba(129,128,127,0.2);
        &.hide {
            transform: translateY(92px);
        }
    }

    &__phone {
        display: flex;
        align-items: center;
        font-size: 24px;
        line-height: 38px;
        color: $black;
        flex-shrink: 0;
        fill: none;
        stroke: $black;
        text-decoration: none;

        svg {
            margin-right: 9px;
        }
    }
}