.prices {
  background: url("../images/texture.jpg");
  background-size: 100%;
  background-position: center 0;
  padding-top: 94px;
  padding-bottom: 105px;
  @media ($tablet) {
    padding-left: 30px;
    padding-right: 30px;
    background: url(../images/texture2.jpg);
    background-size: 100%;
    background-repeat: repeat;
  }
  @media ($mobile) {
    padding-top: 48px;
    padding-bottom: 50px;
    padding-left: 17px;
    padding-right: 17px;
    background: url(../images/texture2.jpg);
    background-size: 100%;
    background-repeat: repeat;
  }
  &__title {
    font-family: "AlgeriaTwo";
    font-size: 40px;
    line-height: 50px;
    font-weight: normal;
    text-align: center;
    color: #181818;
    margin-top: 0;
    margin-bottom: 75px;
    @media ($tablet) {
      margin-bottom: 48px;
    }
    @media ($mobile) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 26px;

    }
  }
  &__table {
    margin: 0 auto;
    width: 100%;
    max-width: 727px;
    margin-bottom: 60px;
    overflow: hidden;
    @media ($mobile) {
      max-width: 500px;
    }
  }
  &__table-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:  stretch;
    &:last-child {
      .prices__table-cell {
        border-bottom: none;
        @media ($mobile){
          border-bottom: 1px solid #a5adb3;
          &:first-child {
            border-bottom: 0;
          }
          &:nth-child(2) {
            border-bottom: 0;
          }
        }
      }
    }
    @media ($mobile){
      flex-wrap: wrap;
      &--mobTitle {
        border-bottom: 1px solid #a5adb3;
      }
    }
  }
  &__table-cell-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 3px;
    padding-bottom: 10px;
    padding-top: 10px;
    @media ($mobile){
      flex-direction: column;
      align-items: flex-end;
      padding-bottom: 10px;
      padding-top: 7px;
      line-height: 17px;
      font-size: 14px;
      padding-left: 0;
    }
  }
  &__table-cell {
    border-bottom: 1px solid #a5adb3;
    min-height: 43px;
    line-height: 20px;
    font-size: 18px;
    width: 130px;
    &:first-child {
      .prices__table-cell-inner {
        justify-content: flex-start;
        text-align: left;
      }
      width: 360px;
    }
    @media ($mobile){
      &:first-child {
        width: 50%;
        border-bottom: 0;
        .prices__table-cell-inner {
          justify-content: flex-start;
          align-items: flex-start;
        }

      }
      &:nth-child(2) {
        width: 50%;
        border-bottom: 0;
        .prices__table-cell-inner {
          padding-top: 12px;
          padding-bottom: 0;
        }
      }
      &:nth-child(3) {
        width: 100%;
        .prices__table-cell-inner {
          padding-top: 0;
          margin-top: -4px;
        }
      }
    }
    &--header {
      border-bottom: 3px solid $light;
      font-weight: bold;
      padding-bottom: 10px;
      padding-top: 0;
      font-size: 16px;
      .prices__table-cell-inner {
        padding-bottom: 3px;
        padding-top: 0;
      }
      @media ($mobile){
        &:first-child {
          width: 100%;
          justify-content: flex-start;
          border-bottom: 3px solid $light;
          .prices__table-cell-inner {
            align-items: flex-start;
          }
        }
      }

    }
  }
  &__table-price {
    @media ($mobile) {
      font-size: 18px;
    }
  }
  &__table-mob-text {
    @media ($mobile) {
      font-size: 12px;
    }
  }
  &__table-title {
    font-family: "DIN Condensed";
    text-transform: uppercase;
    color: #1e1e1e;
    margin: 0;
    padding-top: 33px;
    padding-bottom: 15px;
    padding-left: 3px;
    font-size: 28px;
    line-height: 1;
    font-weight: normal;
    @media ($mobile) {
      padding-left: 0;
      font-size: 20px;
      padding-top: 21px;
    }
  }
  &__table-mob-text {
    display: none;
    @media ($mobile) {
      display: block;
    }
  }
  &__btn-more {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    min-height: 63px;
    line-height: 63px;
    font-family: "DIN Condensed";
    font-size: 24px;
    text-transform: uppercase;
    background: transparent;
    border: 3px solid $light;
    @media ($mobile) {
      min-height: 54px;
      line-height: 54px;
      height: 54px;
      width: 100%;
    }
  }

}
