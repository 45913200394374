.steps {
  margin: 0 auto;
  max-width: 1320px;
  padding-left: 20px;
  padding-right: 20px;
  @media ($tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media ($mobile) {
    padding-left: 17px;
    padding-right: 17px;
  }
  &__title {
    font-family: "AlgeriaTwo";
    font-size: 64px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 70px;
    line-height: 1;
    @media ($tablet) {
      margin-bottom: 48px;
      font-size: 42px;
      margin-bottom: 64px;
    }
    @media ($mobile) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 26px;
      text-align: center;
    }
  }
  &__wrapper {
    display: flex;
    @media ($tablet) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__step-list {
    position: relative;
    width: 48.5%;
    border-top: 3px solid $light;
    border-bottom: 3px solid $light;
    @media ($tablet) {
      width: 100%;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $light;
    }
    &:before {
      top: -8px;
    }
    &:after {
      bottom: -8px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    padding-top: 19px;
    padding-bottom: 19px;
    border-bottom: 1px solid $light;
    &:last-child{
      border-bottom: 0;
    }
  }
  &__number {
    font-family: "AlgeriaTwo";
    color: #181818;
    font-size: 100px;
    line-height: 1;
    text-align: center;
    width: 137px;
    flex-shrink: 0;
    background-position: center center;
    background-size: 60%;
    background-repeat: no-repeat;
    &--1 {
      background-image: url(../images/icon1.png);
    }
    &--2 {
      background-image: url(../images/icon2.png);
    }
    &--3 {
      background-image: url(../images/icon3.png);
    }
    &--4 {
      background-image: url(../images/icon4.png);
    }
    &--5 {
      background-image: url(../images/icon5.png);
    }
    @media ($mobile) {
      width: 65px;
      font-size: 60px;
      background-size: 80%;

    }
  }
  &__content {
    padding-left: 5px;
    padding-right: 40px;
    @media ($tablet) {
      padding-left: 0;
      padding-right: 18%;
    }
    @media ($mobile){
      padding-right: 0;
      padding-left: 10px;
    }
  }

  &__content-title {
    font-family: "DIN Condensed";
    font-weight: normal;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 28px;
    line-height: 1;
    margin-bottom: 9px;
    @media ($mobile){
      font-size: 20px;
    }
  }
  &__content-text {
    font-size: 18px;
    line-height: 21px;
    @media ($mobile){
      font-size: 14px;
    }
  }
  &__about {
    margin-left: 10.5%;
    width: 42.5%;
    max-width: 500px;
    @media ($tablet) {
      width: 100%;
      max-width: 476px;
      margin-top: 100px;
      margin-left: 0;
    }
    @media ($mobile) {
      margin-top: 50px;
    }
  }

  &__about-top {
    display: flex;
    align-items: center;
    font-family: "DIN Condensed";
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 45px;
    img {
      width: 179px;
      border-radius: 50%;
      flex-shrink: 0;
      margin-right: 33px;
      border: 5px solid white;
      box-sizing: content-box;
      @media ($mobile) {
        width: 100px;
        margin-right: 24px;
      }
    }
    @media($mobile) {
      margin-bottom: 30px;
    }
  }
  &__about-title {
    font-size: 32px;
    font-weight: normal;
    margin: 0;
    @media ($mobile) {
      font-size: 22px;
    }
  }
  &__about-desc {
    max-width: 440px;
    background-image:url(../images/icon-quote.png);
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 36px;
    p {
      margin-top: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media ($mobile) {
      font-size: 14px;
      line-height: 18px;
      margin-top: 0;
      background-size: contain;
    }
  }
  &__about-name {
    font-family: "DIN Condensed";
    text-transform: uppercase;
    font-size: 28px;
    margin-bottom: 10px;
    @media ($mobile) {
      font-size: 20px;
      line-height: 34px;
      margin-bottom: 0;
    }
  }
  &__about-job {
    margin-top: 0;
    @media ($mobile) {
      font-size: 14px;
    }
  }


}
