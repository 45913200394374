.top {
  position: relative;
  background-color: #0e0e0e;
  color: $white;
  overflow: hidden;
  &__bg {
    position: absolute;
    width: 100%;
    top: -140px;
    height: auto;
    z-index: 1;
    &--bottom {
      top: auto;
      bottom: 0;
      z-index: 0;
    }
    @media ($tablet) {
      top: -29px;
      &--bottom {
        top: auto;
      }
    }
    @media ($mobile){
      top: 0;
      &--bottom {
        top: auto;
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 180px;
    position: relative;
    z-index: 2;
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    @media ($tablet){
      padding-top: 52px;
      padding-left: 0;
      padding-right: 0;
    }
    @media ($mobile){
      display: block;
      padding-left: 17px;
      padding-right: 17px;
      padding-top: 9px;
    }
  }
  &__title-cont {
    position: relative;
    padding-bottom: 170px;
    border-bottom: 1px solid #ddcfb3;
    @media ($tablet){
      padding-bottom: 136px;
    }
    @media ($mobile) {
      padding-bottom: 66px;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 5px;
      background-color: #ddcfb3;
      bottom: 17px;
    }
  }
  &__title {
    position: relative;
    font-family: "AlgeriaTwo";
    font-size: 74px;
    line-height: 88px;
    font-weight: normal;
    text-align: center;
    padding-bottom: 60px;
    letter-spacing: 0.8px;
    @media ($tablet){
      font-size: 46px;
      line-height: 52px;
      padding-bottom: 30px;
    }
    @media ($mobile) {
      font-size: 24px;
      line-height: 30px;
      padding-bottom: 20px;
      margin-bottom: 0;
    }
    &:after {
      content: "";
      position: absolute;
      width: 21%;
      left: 50%;
      margin-left: -10.5%;
      height: 3px;
      background-color: #f3f3f3;
      bottom: 0;
      @media ($tablet) {
        width: 30%;
        margin-left: -15%;
      }
      @media ($mobile) {
        width: 105px;
        margin-left: -50px;
      }
    }
    &--secondary {
      font-size: 64px;
      line-height: 72px;
      margin-top: 0;
      margin-bottom: 80px;
      padding-bottom: 0;
      &:after {
        display: none;
      }
      @media ($tablet) {
        font-size: 42px;
        margin-bottom: 50px;
      }
      @media ($mobile) {
        font-size: 22px;
        letter-spacing: 0;
        margin-bottom: 20px;
      }
    }
  }
  &__desc {
    margin: 0 auto;
    max-width: 810px;
    @media ($tablet){
      max-width: 705px;
    }
    @media ($mobile) {
      max-width: 100%;
    }

  }
  &__text {
    text-align: center;
    font-family: "HelveticeNeue";
    font-size: 28px;
    line-height: 34px;
    font-weight: normal;
    margin: 0;
    padding-top: 40px;
    padding-bottom: 50px;
    @media ($tablet) {
      padding-top: 0;
      font-size: 22px;
      max-width: 650px;
      margin: 0 auto;
      line-height: 26px;
      padding-bottom: 143px;
    }
    @media ($mobile) {
      font-size: 14px;
      padding-top: 23px;
      line-height: 18px;
      padding-bottom: 30px;
      max-width: 250px;
      margin: 0 auto;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    min-width: 810px;
    @media ($tablet){
      min-width: 705px;
    }
    @media ($mobile) {
      flex-direction: column;
      min-width: 280px;
      align-items: center;
    }
  }
  &__btn-left {
    height: 84px;
    width: 297px;
    @media ($tablet){
      max-width: 300px;
    }
    @media ($mobile){
      font-family: "DIN Condensed";
      max-width: 400px;
      width: 100%;
      height: 50px;
      font-size: 24px;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 50px;
      min-height: 50px;
      margin-bottom: 19px;
    }
  }
  &__btn-right {
    height: 84px;
    width: 437px;
    @media ($tablet){
      max-width: 380px;
    }
    @media ($mobile){
      max-width: 400px;
      width: 100%;
      font-family: "DIN Condensed";
      max-width: 400px;
      width: 100%;
      height: 50px;
      font-size: 24px;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 50px;
      min-height: 50px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  &__advantages {
    width: 100%;
    padding-top: 100px;
    @media ($tablet) {
      padding-top: 80px;
    }
    @media ($mobile) {
      padding-top: 21px;
    }
  }
  &__services {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 239px;
    @media ($tablet) {
      padding-top: 77px;
      padding-bottom: 118px;
    }
    @media ($mobile) {
      padding-top: 10px;
      padding-bottom: 69px;
    }
  }

}
