.header {
  font-family: "DIN Condensed";
  background-color: #fff;
  border-bottom: 5px solid #ddcfb3;
  box-shadow: 0px 10px 89px -7px rgba(129,128,127,1);
  @media ($mobile){
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100%;
    transition: all 0.5s;
    box-shadow: 0px 10px 89px -7px rgba(129,128,127,0.2);
    &.hide {
      transform: translateY(-92px);
    }
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1800px;
    margin: 0 auto;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 31px;
    padding-left: 50px;
    padding-right: 50px;
    @media ($tablet) {
      width: 100%;
      padding-left: 16px;
      padding-right: 31px;
      padding-top: 27px;
      padding-bottom: 25px;
    }
    @media ($mobile) {
      padding-left: 16px;
      padding-right: 17px;
      width: 100%;
      padding-top: 6px;
      padding-bottom: 18px;
    }
  }
  &__logo {
    transition: filter 0.3s;
    text-decoration: none;
    font-size: 0;
    display: inline-flex;
    @media ($tablet) {
      svg {
        width: 200px;
        height: 69px;
      }
    }
    @media ($mobile) {
      width: 122px;
      height: 62px;
    }
  }
  &__left,
  &__right {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  &__left {
    @media ($mobile) {
      width: 100%;
      justify-content: space-between;
    }
  }
  &__right {
    justify-content: flex-end;
    @media ($mobile) {
      display: none;
    }
  }
  &__phone {
    display: flex;
    align-items: center;
    font-size: 24px;
    line-height: 38px;
    color: $black;
    margin-right: 6.6%;
    flex-shrink: 0;
    fill: none;
    stroke: $black;
    text-decoration: none;
    @media ($tablet) {
      display: none;
    }
    img,
    svg {
      margin-right: 9px;
    }
  }
  &__btn {
    @media ($tablet) {
      min-width: 261px;
      min-height: 74px;
      letter-spacing: 0.7px;
    }

  }

}
