.work {
  background: url(../images/texture2.jpg);
  background-size: cover;
  padding-top: 122px;
  padding-bottom: 190px;
  @media ($tablet) {
    padding-top: 141px;
    padding-bottom: 100px;
  }
  @media ($mobile) {
    padding-top: 50px;
    padding-bottom: 60px;
    background-size: 100%;
    background-repeat: repeat;
  }
}
