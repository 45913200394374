.send-block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding-top: 150px;
  padding-bottom: 50px;
  position: relative;
  background-color: #000;
  @media ($mobile) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &__title {
    font-family: "DIN Condensed";
    font-weight: normal;
    font-size: 42px;
    line-height: 1;
    padding-bottom: 28px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 20px;
    border-bottom: 3px solid $light;
    @media ($mobile) {
      font-size: 32px;
    }
  }
  &__text {
    @media ($mobile) {
      text-align: center;
    }
  }

}
