.feedback-form {
  color: white;
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  background-color: rgba(0,0,0,0.41);
  padding-top: 31px;
  padding-bottom: 35px;
  padding-right: 25px;
  padding-left: 40px;
  @media ($tablet) {
    padding-top: 28px;
    padding-left: 26px;
  }
  @media ($mobile) {
    flex-direction: column;
    padding-top: 16px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
  }
  &--popup {
    background-image: url("../images/top-bg1.jpg");
    background-position: center center;
    flex-direction: column;
    padding-top: 37px;
    padding-left: 33px;
    padding-right: 35px;
    padding-bottom: 0;
    height: 750px;
    border: 1px solid white;
    position: relative;
    @media ($tablet) {
      height: 800px;
    }
    @media ($mobile) {
      padding: 17px;
      border: none;
    }
    .feedback-form {
      &__heading {
        width: 100%;
        padding: 0;
        border: none;
        @media ($mobile) {
          display: block;
        }
      }
      &__phone {
        @media ($mobile) {
          display: block;
        }
      }
      &__title {
        font-size: 35px;
        line-height: 38px;
        margin-bottom: 25px;
        @media (max-height: 750px) {
          padding-right: 50px;
        }
        @media ($mobile) {
          font-size: 24px;
          line-height: 28px;
        }
      }
      &__desc {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 28px;
        @media ($mobile) {
          font-size: 16px;
          line-height: 19px;
          margin-bottom: 37px;
        }
      }
      &__contacts {
        background-color: rgba(0,0,0,0.66);
        padding-left: 31px;
        padding-right: 35px;
        margin-left: -33px;
        margin-right: -35px;
        padding-bottom: 20px;
        padding-top: 15px;
        p {
          margin-top: 0;
          @media ($tablet) {
            margin-bottom: 10px;
          }
          @media ($mobile) {
            margin-bottom: 0;
          }
        }
        a {
          color: white;
          text-decoration: none;
        }
        @media ($tablet) {
          margin-top: 0;

        }
        @media ($mobile) {
          padding-left: 17px;
          margin-left: -17px;
          margin-right: -17px;
          padding-right: 0;
        }

      }
      &__form-wrapper {
        width: 100%;
        padding-left: 0;
        padding-top: 25px;
        padding-bottom: 0;
        margin-bottom: 15px;
        p {
          margin-top: 0;
          font-size: 24px;
          line-height: 1;
          margin-bottom: 22px;
        }
      }
      &__inputs-row input{
        @media ($tablet) {
          margin-bottom: 0;
        }
        @media ($mobile) {
          width: 100%;
        }
      }
      &__upload-cont {
        padding-top: 25px;
      }
      &__button {
        @media ($mobile) {
          width: 100%;
          min-width: 250px;
        }
      }

    }
    @media ($tablet) {
      margin-bottom: 50px;
    }
    @media ($mobile) {
      margin-bottom: 0;
    }

  }
  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 5px solid $light;
    width: 48.3%;
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 30px;
    @media ($tablet) {
      padding-top: 0;
      padding-right: 14px;
      justify-content: center;
      border-width: 5px;
      width: 50.5%;
    }
    @media ($mobile) {
      border-right: none;
      border-bottom: 5px solid $light;
      width: 100%;
      padding-bottom: 15px;
    }
  }
  &__form-wrapper {
    padding-left: 53px;
    padding-bottom: 30px;
    padding-top: 20px;
    width: 51.4%;
    @media ($tablet) {
      padding-left: 20px;
      width: 49.5%;
      padding-bottom: 4px;
    }
    @media ($mobile) {
      width: 100%;
      padding-left: 0;
      padding-top: 33px;
      padding-bottom: 25px;

    }
  }
  &__title {
    text-transform: uppercase;
    font-family: "DIN Condensed";
    font-weight: normal;
    font-size: 53px;
    line-height: 63px;
    color: #f9f9f2;
    margin: 0;
    @media ($tablet) {
      font-size: 32px;
      line-height: 38px;
      margin-top: -5px;
    }
    @media ($mobile) {
      font-size: 24px;
      line-height: 28px;
      padding-right: 0;
      margin-top: 0;
      margin-bottom: 15px;
    }
  }

  &__contacts {
    font-size: 24px;
    a {
      color: white;
      text-decoration: none;
    }
    br {
      display: none;
    }

    @media ($tablet) {
      margin-top: 46px;
    }
    @media ($mobile) {
      padding-left: 3px;
      margin-top: 0;
      font-size: 22px;
      padding-bottom: 0;
      br {
        display: inline-block;
      }
    }
    p {
      margin-bottom: 0;
      @media ($tablet) {
        margin-top: 0;
      }
    }
    img {
      margin-left: 8px;
      margin-bottom: -3px;
      @media ($mobile){
        margin-left: 4px;
      }
    }
  }
  &__phone {
    margin-top: 0;
    font-size: 24px;
    font-weight: normal;
    a {
      font-size: 32px;
      color: white;
      text-decoration: none;
    }
    br {
      display: block;
    }
    @media ($mobile) {
      display: none;
      font-size: 22px;
      a {
        font-size: 22px;
      }
    }
    &--onlymob {
      display: none;
      @media ($mobile) {
        display: block;
      }
    }
  }
  &__desc {
    font-size: 24px;
    line-height: 30px;
    margin-top: 0;
    margin-bottom: 50px;
    b {
      display: block;
      margin-bottom: 10px;
      font-weight: normal;
      font-size: 32px;
      line-height: 1;
      text-transform: uppercase;
      font-family: "DIN Condensed";
      @media ($tablet) {
        line-height: 38px;
      }
      @media ($mobile) {
        font-size: 24px;
        line-height: 28px;
      }

    }
    @media ($tablet) {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 25px;
    }
    @media ($mobile) {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 37px;
      letter-spacing: 0.1px;
    }
  }

  &__form {
    width: 100%;
  }
  &__upload-input {
    display: none;

  }
  &__inputs-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    input {
      width: 100%;
      color: white;
      background: transparent;
      border: 1px solid white;
      font-size: 0;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 16px;
      padding-left: 15px;
      max-height: 48px;
    }
  }
  &__input {
    position: relative;
    width: 47%;
    @media ($tablet) {
      width: 100%;
      margin-bottom: 23px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    @media ($mobile) {
      font-size: 14px;
    }
  }
  &__label {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 15px;

  }
  &__upload-cont {
    padding-top: 26px;
    @media ($tablet) {
      padding-top: 15px;
    }
  }
  &__fake-upload {
    display: flex;
    label {
      color: white;
      background: transparent;
      border: 1px solid white;
      font-size: 18px;
      line-height: 1;
      padding-top: 12px;
      padding-bottom: 16px;
      padding-left: 25px;
      padding-right: 25px;
      @media ($tablet) {
        padding-left: 17px;
        padding-right: 17px;
      }
      @media ($mobile){
        padding-left: 8px;
        padding-right: 6px;
        font-size: 14px;
        padding-top: 17px;
      }
    }
  }
  &__fake-input {
    color: white;
    background: transparent;
    border: 1px solid white;
    font-size: 18px;
    line-height: 1;
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 15px;
    flex-grow: 1;
  }
  &__fake-label {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 15px;
     @media ($mobile) {
        padding-top: 4px;
        font-size: 14px;
      }
  }
  &__button {
    margin-top: 32px;
    width: 100%;
    min-height: 61px;
    height: 61px;
    letter-spacing: 0.9px;
    line-height: 1;
    @media ($tablet) {
      margin-top: 25px;
    }
    @media ($mobile){
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
      min-height: 52px;
      height: 52px;
      line-height: 1;
      vertical-align: middle;
      width: 100%;
    }
  }

}
