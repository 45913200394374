.advantages {

  @media ($tablet) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media ($mobile) {
    padding-left: 0;
    padding-right: 0;
    max-width: 500px;
    margin: 0 auto;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media ($mobile) {
      display: block;
    }
  }
  &__item {
    display: flex;
    width: 30%;
    margin-bottom: 90px;
    margin-right: 1.5%;
    &:nth-child(3n) {
      margin-right: 0;
    }
    @media ($tablet) {
      width: 50%;
      margin-right: 0;
      margin-bottom: 40px;
    }
    @media ($mobile){
      width: 100%;
      margin-bottom: 33px;
    }
  }
  &__image {
    fill: none;
    stroke: white;
    width: 90px;
    flex-shrink: 0;
    border-right: 3px solid $light;
    @media ($tablet) {
      width: 100px;
      display: flex;
      justify-content: flex-end;
      padding-right: 15px;
      &--gift {
        svg {
          width: 60px;
        }
      }
      &--carincomp {
        svg {
          width: 66px;
          height: 66px;
        }
      }
      &--caropened {
        svg {
          width: 84px;
          height: 41px;
        }
      }
      &--painting {
        svg {
          width: 68px;
          height: 55px;
        }
      }
      &--carchecked {
        svg {
          width: 75px;
          height: 64px;
        }
      }
      &--caropened {
        svg {
          height: 25px;
        }
      }

    }
    @media ($mobile) {
      width: 59px;
      padding-right: 10px;
      &--gift {
        svg {
          width: 39px;
          height: 39px;
        }
      }
      &--carincomp {
        svg {
          width: 42px;
          height: 53px;
        }
      }
      &--painting {
        svg {
          width: 41px;
          height: 41px;
        }
      }
      &--carrepaired {
        svg {
          //width: 75px;
          height: 30px;
        }
      }
    }
  }
  &__content {
    padding-left: 18px;
    @media ($tablet) {
      padding-top: 5px;
    }
    @media ($mobile) {
      padding-left: 9px;
    }
  }
  &__title {
    font-family: "DIN Condensed";
    font-weight: normal;
    margin-top: 0;
    line-height: 1;
    font-size: 28px;
    margin-bottom: 17px;
    text-transform: uppercase;
    @media ($desktop-xs) {
      font-size: 22px;
    }
    @media ($tablet) {
      line-height: 28px;
      letter-spacing: 0.8px;
      margin-bottom: 5px;
      padding-right: 25px;
      margin-top: -5px;
    }
    @media ($mobile) {
      margin-bottom: 2px;
      margin-top: -9px;
      font-size: 16px;
      line-height: 22px;
    }

  }
  &__desc {
    font-size: 16px;
    color: #dbdbdb;
    @media ($tablet) {
      padding-right: 17px;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 20px;
      padding-bottom: 11px;
    }
    @media ($mobile) {
      line-height: 16px;
      font-size: 14px;
    }
  }

}
