$container-width: 1170px;

/* COLORS */

$black: #292929;
$white: #fff;
$red: #c6091c;
$light: #e1ddc3;

/* MEDIA QUERIES */
$desktop-md: unquote("max-width: 1800px");
$desktop-l: unquote("min-width: 1681px");
$desktop-s: unquote("max-width: 1680px");
$desktop-xs:unquote("max-width: 1280px");;
$tablet: unquote("max-width: 1023px");
$mobile: unquote("max-width: 767px");
