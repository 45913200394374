.popup {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 6;
  width: 640px;
  height: 100%;
  opacity: 0;
  min-height: 750px;
  @media ($tablet) {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (max-height: 800px) {
    display: block;
    height: 100%;
    min-height: 100%;
    overflow-y: scroll;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  &:focus {
    outline: none;
  }
  &__close {
    position: absolute;
    top: -50px;
    right: -50px;
    fill: white;
    cursor: pointer;
    @media (max-height: 830px) {
      top: 15px;
      right: -60px;
    }
    @media (max-height: 750px) {
      top: 44px;
      right: 37px;
    }
    @media ($tablet) {
      top: 10px;
      right: 10px;
    }
    @media ($mobile) {
      width: 20px;
      height: 20px;
      top: 10px;
      right: 10px;
    }
  }

}
