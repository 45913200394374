.services {
  @media ($tablet) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media ($mobile) {
    padding-left: 0;
    padding-right: 0;
    max-width: 500px;
    margin: 0 auto;
  }
  .top__title {
    @media ($tablet) {
      margin-bottom: 37px;
    }
    @media ($mobile) {
      margin-bottom: 3px;
    }
  }
  &__tabs {
    border-top: 3px solid $light;
    border-bottom: 3px solid $light;
    text-align: center;
    @media ($mobile) {
      text-align: left;
      padding-top: 6px;
      padding-bottom: 13px;
      margin-left:-5.5px;
      margin-right:-5.5px;
    }
  }
  &__tab {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    color: white;
    text-transform: uppercase;
    font-family: "DIN Condensed";
    padding-top: 20px;
    padding-bottom: 20px;
    margin-right: 60px;
    &:last-child {
      margin-right: 0;
    }
    &.current {
      color: $light;
      text-decoration: none;
    }
    @media ($tablet) {
      font-size: 20px;
      padding-top: 25px;
      padding-bottom: 27px;
      margin-right: 46px;
    }
    @media ($mobile) {
      margin-right: 16px;
      font-size: 20px;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 32px;
      margin-right: 5.5px;
      margin-left: 5.5px;
    }
  }
  &__content {
    padding-top: 50px;
    @media ($tablet) {
      padding-top: 38px;
    }
    @media ($mobile) {
      padding-top: 25px;
    }
  }
  &__content-item {
    display: none;
    &.opened {
      display: flex;
    }
    @media ($tablet) {
      flex-direction: column;
    }
  }
  &__images-cont {
    width: 68%;
    padding-right: 5%;
    font-size: 0;
    overflow: hidden;
    flex-grow:0;
    img {
      height: initial;
      max-width: 1000px;
      &:active,
      &:focus {
        outline: none;
      }
    }
    @media ($desktop-xs) {
      width: 63%;
      img {
        width: 100%;
      }
    }
    @media ($tablet) {
      width: 100%;
      padding-right: 0;
      overflow: visible;
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  &__image {
    position: relative;
    font-family: "DIN Condensed";
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 155px;
      height: 40px;
      text-align: center;
      font-size: 24px;
      line-height: 40px;
      text-transform: uppercase;
      background-color: $light;
      color: black;
      @media ($mobile) {
        width: 62px;
        height: 22px;
        font-size: 14px;
        line-height: 22px;
      }
    }
    img {
      border-left: 1.5px solid $light;
      @media ($mobile) {
          border-width: 1px;
        }
    }
    &--before{
      img {
        border-right: 1.5px solid $light;
        border-left: none;
        @media ($mobile) {
          border-width: 1px;
        }
      }

      &:before {
        content:"До"
      }
    }
    &--after{
      &:before {
        content:"После"
      }
    }

  }
  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32%;
    flex-shrink: 0;
    //padding-left: 5%;
    font-size: 18px;
    line-height: 24px;
    @media ($desktop-xs) {
      width: 38%;
    }
    @media ($tablet) {
      position: relative;
      width: 100%;
      padding-left: 0;
      padding-top: 46px;
    }
    @media ($mobile) {
      padding-top: 29px;
    }
  }
  &__subtitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #ddcfb3;
    @media ($tablet) {
      margin-bottom: 12px;
    }
    @media ($mobile) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 7px;
    }

  }
  &__title {
    margin-top: 0;
    font-family: "DIN Condensed";
    text-transform: uppercase;
    color: white;
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 15px;
    @media ($tablet) {
      font-size: 40px;
      margin-bottom: 8px;
    }
    @media ($mobile) {
      font-size: 19px;
      line-height: 26px;
    }
  }
  &__desc {
    font-size: 18px;
    line-height: 24px;
    padding-right: 50px;
    margin-bottom: 25px;
    li {
      display: flex;
      &:before {
        margin-right: 5px;
        width: 1em;
        text-align: left;
        display: inline-block;
      }
      &:nth-child(1):before {
        content: 'a)';
      }
      &:nth-child(2):before {
        content: 'б)';
      }
      &:nth-child(3):before {
        content: 'в)';
      }
      &:nth-child(4):before {
        content: 'г)';
      }
      &:nth-child(5):before {
        content: 'д)';
      }
      &:nth-child(6):before {
        content: 'ж)';
      }
    }

    @media ($tablet){
      position: absolute;
      padding-top: 46px;
      top: 37px;
      line-height: 22px;
      padding-right: 32px;
      padding-left: 13px;
      right: 0;
      width: 50%;
      margin-top: 0;
      margin-bottom: 0;
    }
    @media ($mobile) {
      position: static;
      font-size: 14px;
      line-height: 17px;
      width: 100%;
      padding: 0;
      margin-bottom: 15px;
    }
    &--big {
      padding-right: 10px;
      p {
        margin-bottom: 5px;
      }
      @media ($tablet) {
        top: 0;
        padding-right: 0;
        padding-top: 0;
      }
    }
  }
  &__price {
    font-family: "DIN Condensed";
    font-size: 38px;
    line-height: 1;
    margin-bottom: 30px;
    @media ($tablet) {
      margin-bottom: 37px;
    }
    @media ($mobile) {
      font-size: 18px;
      margin-bottom: 26px;
    }
  }

  &__btn {
    font-size: 24px;
    max-width: 400px;
    svg {
      fill: white;
      margin-right: 12px;
      margin-bottom: 3px;
    }
    @media ($tablet) {
      max-width: 354px;
      min-height: 84px;
      padding-left: 27px;
    }
    @media ($mobile) {
      font-size: 24px;
      height: 51px;
      line-height: 1;
      min-height: 51px;
      padding: 0;

    }
  }

  &__slider {
    position: relative;
    padding-left: 40px;
    @media ($tablet) {
      padding-right: 40px;
    }
    @media ($mobile) {
      margin-left: -17px;
      margin-right: -17px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    font-size: 0;
    width: 30px;
    height: 56px;
    border: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    padding: 0;
    flex-shrink: 0;
    z-index: 4;
    top: 50%;
    transform:translateY(-28px);
    &:active,
    &:focus {
      outline: none;
    }
    @media ($mobile) {
      width: 16px;
      height: 30px;
      transform: translateY(-15px);
    }
  }

  .slick-next {
    background-image: url("../images/arrow-next.svg");
    right: -40px;
    @media ($tablet) {
      right: 0;
    }
    @media ($mobile) {
      right: 5px;
    }
  }
  .slick-prev {
    background-image: url("../images/arrow-prev.svg");
    left: 0;
    @media ($mobile) {
      left: 5px;
    }

  }





}
