.contacts {
  position: relative;
  background-color: black;
  &__map {
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    .ymaps-map {
      background: transparent;
    }
  }
  &__wrapper {
    position: relative;
    max-width: 1320px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
    padding-top: 102px;
    padding-bottom: 113px;
    @media ($tablet) {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 125px;
      padding-bottom: 102px;
    }
    @media ($mobile) {
      padding-left: 17px;
      padding-right: 17px;
      padding-bottom: 357px;
    }
  }
  &__info {
    position: relative;
    z-index: 5;
    background-color: #fff;
    width: 436px;
    padding-top: 28px;
    padding-left: 18px;
    padding-right: 17px;
    padding-bottom: 23px;
    box-shadow: 0px 21px 35px 0px rgba(0,0,0,0.28);
    @media ($tablet) {
      width: 353px;
      padding-top: 31px;
      padding-bottom: 27px;
    }
    @media ($mobile) {
      width: 100%;
      margin: 0 auto;
      max-width: 500px;
      padding-top: 19px;
    }
  }

  &__title {
    font-family: "AlgeriaTwo";
    font-size: 48px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 31px;
    padding-left: 6px;
    line-height: 1;
    @media ($tablet) {
      font-size: 42px;
      margin-bottom: 32px;
      padding-left: 0;
    }
    @media ($mobile) {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 17px;
    }
  }

  &__desc {
    display: block;
    color: inherit;
    text-decoration: none;
    &--mail {
      @media ($mobile) {
        margin-top: 5px;
      }
    }
  }

  &__block {
    border-top: 2px solid $light;
    padding-left: 5px;
    padding-top: 25px;
    padding-bottom: 32px;
    font-size: 24px;
    line-height: 34px;
    &:last-child {
      padding-bottom: 0;
      .contacts__subtitle {
        @media ($tablet) {
          margin-bottom: 10px;
        }
        @media ($mobile) {
          margin-bottom: 4px;
        }
      }
    }
    @media ($tablet) {
      font-size: 20px;
      line-height: 33px;
      padding-bottom: 22px;
    }
    @media ($mobile) {
      font-size: 16px;
      line-height: 20px;
      padding-left: 0;
    }
  }

  &__subtitle {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 11px;
    @media ($tablet) {
      margin-bottom: 5px;
    }
    @media ($mobile) {
      font-size: 14px;
      margin-bottom: 13px;
    }
  }

}
